import { Map } from 'immutable';

// **** ////
// Don't use this for functions anymore, use utils and write it in TS!
//****** */

export const initialState = Map({
  hasGoogleMapError: false,
  serviceChoiceModalFlag: false,
  modalShown: false,
  view: 'list',
});
