import { Bounds, fitBounds } from 'google-map-react';
import { maxBy, minBy } from 'lodash';
import { CombinedVenue } from 'types/models';

export const getCenterAndZoom = (
  venues: CombinedVenue[],
): {
  center: { lat: number; lng: number };
  zoom: number;
  newBounds: Bounds;
} => {
  if (venues.length === 0) {
    return {
      center: { lat: 54.5, lng: -2 }, // Default center for UK
      zoom: 6,
      newBounds: {
        ne: { lat: 60.85, lng: 1.76 },
        sw: { lat: 49.86, lng: -8.64 },
        nw: { lat: 60.85, lng: -8.64 },
        se: { lat: 49.86, lng: 1.76 },
      },
    };
  }

  let bounds: Bounds;

  if (venues.length === 1) {
    const { latitude, longitude } = venues[0].coordinates;
    bounds = {
      ne: { lat: latitude + 0.005, lng: longitude + 0.005 },
      sw: { lat: latitude - 0.005, lng: longitude - 0.005 },
      nw: { lat: latitude + 0.005, lng: longitude - 0.005 },
      se: { lat: latitude - 0.005, lng: longitude + 0.005 },
    };
  } else if (venues.length > 200) {
    // Centre map on the UK if there are over 200 locations
    bounds = {
      ne: { lat: 60.85, lng: 1.76 },
      sw: { lat: 49.86, lng: -8.64 },
      nw: { lat: 60.85, lng: -8.64 },
      se: { lat: 49.86, lng: 1.76 },
    };
  } else {
    const maxLatVenue = maxBy(venues, (v) => v.coordinates.latitude);
    const minLatVenue = minBy(venues, (v) => v.coordinates.latitude);
    const maxLngVenue = maxBy(venues, (v) => v.coordinates.longitude);
    const minLngVenue = minBy(venues, (v) => v.coordinates.longitude);

    bounds = {
      ne: {
        lat: maxLatVenue?.coordinates.latitude ?? 0,
        lng: maxLngVenue?.coordinates.longitude ?? 0,
      },
      sw: {
        lat: minLatVenue?.coordinates.latitude ?? 0,
        lng: minLngVenue?.coordinates.longitude ?? 0,
      },
      nw: {
        lat: maxLatVenue?.coordinates.latitude ?? 0,
        lng: minLngVenue?.coordinates.longitude ?? 0, // Max latitude, min longitude
      },
      se: {
        lat: minLatVenue?.coordinates.latitude ?? 0,
        lng: maxLngVenue?.coordinates.longitude ?? 0, // Min latitude, max longitude
      },
    };
  }

  const mapContainer = document.getElementById('map-container');
  const width = mapContainer?.clientWidth || 800;
  const height = mapContainer?.clientHeight || 400;

  return fitBounds(bounds, { width, height });
};
