import { useState, useEffect, useRef } from 'react';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { selectDefaultThemeColour } from 'core/selectors';
import cs from 'classnames';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { LoggedInHeader, LoggedOutHeader, Logo } from 'core/components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmVenueModal } from 'venue/components/ConfirmVenueModal';
import { removeNotification, setDefaultThemeColour } from 'core/actions';
import ReactDOM from 'react-dom';
import { useGiftCard } from 'contexts/GiftCardContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useBasket } from 'contexts/BasketContext';
import { useHistory } from 'react-router';
import { useVenueSearch } from 'hooks/useVenueSearch';
import { useServices, useVenues } from 'contexts/VenueContext';
import { useVouchers } from 'contexts/VoucherContext';
import { useCheckout } from 'contexts/CheckoutContext';
import { useGlobalUser } from 'contexts/UserContext';
import { AiFillHome } from 'react-icons/ai';

export const Header: React.FC = () => {
  const {
    accountDeletionOnly,
    disableSearch,
    homepage,
    env,
  } = useConfig();

  const { selectVenue } = usePhrases();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { isLoggedIn } = useGlobalUser();
  const { removeAllGiftCards, redeemedGiftCards } = useGiftCard();
  const { removeAllRewards, redeemedLoyaltyRewards } = useLoyaltyRewards();
  const { setSearchTerm } = useVenueSearch();
  const { setSelectedVenue } = useVenues();
  const { setSelectedService } = useServices();
  const { basket, clearBasket } = useBasket();
  const { clearCheckout } = useCheckout();
  const { redeemedVouchers, voidAllVouchers } = useVouchers();

  const [showConfirmVenueModal, setShowConfirmVenueModal] =
    useState<boolean>(false);

  const headerRef = useRef(null);

  const defaultThemeColour = useSelector(selectDefaultThemeColour);

  const basketIsEmpty = basket.length === 0;
  const hasLoyaltyRewards = redeemedLoyaltyRewards.length > 0;
  const hasGiftCards = redeemedGiftCards.length > 0;

  useEffect(() => {
    if (headerRef?.current !== null) {
      //Is there a better way of doing this? I'd like to not use findDOMNode, but
      //class component refs are making it hard!
      // eslint-disable-next-line react/no-find-dom-node
      const element = ReactDOM.findDOMNode(headerRef.current);
      const theme = getComputedStyle(element as Element);
      dispatch(setDefaultThemeColour(theme.backgroundColor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchOnRedirect = () => {
    setSelectedVenue(undefined);
    setSelectedService(-1);
    setSearchTerm('');
    dispatch(removeNotification());
  };

  const redirectToVenues = () => {
    if (!basketIsEmpty) {
      setShowConfirmVenueModal(true);
    } else {
      disableSearch === true
        ? window.open(homepage, '_self')
        : history.push('/venues');
      dispatchOnRedirect();
    }
  };

  const handleConfirmVenueChange = () => {
    if (!basketIsEmpty) {
      if (redeemedVouchers.length > 0) {
        voidAllVouchers();
      }

      if (hasGiftCards) {
        removeAllGiftCards();
      }

      if (hasLoyaltyRewards) {
        removeAllRewards();
      }
    }

    disableSearch === true
      ? window.open(homepage, '_self')
      : history.push('/venues');
    clearBasket();
    clearCheckout();
    setShowConfirmVenueModal(false);
    dispatchOnRedirect();
  };

  const handleDismissVenueChange = () => {
    setShowConfirmVenueModal(false);
  };

  const isDevEnv = env === 'qa1' || env === 'qa2' || env === 'development';

  const isOnVenuesPage = location.pathname === '/venues';

  const venuesListClasses = cs(
    'header-link select-venue-list',
    isOnVenuesPage ? 'visibility-hidden' : '',
  );

  const headerContainerClasses = cs(
    'header-navbar-container',
    accountDeletionOnly && 'header-navbar-container-account-deletion',
    isDevEnv && 'header-navbar-container-dev',
  );

  return (
    <nav className="header-navbar" data-testid="header-nav-bar" ref={headerRef}>
      <div className="container">
        {defaultThemeColour && (
          <Helmet>
            <meta name="theme-color" content={defaultThemeColour} />
          </Helmet>
        )}
        <div className={headerContainerClasses}>
          {!accountDeletionOnly && !disableSearch ? (
            <Link
              to="/venues"
              onClick={(e) => {
                e.preventDefault();
                redirectToVenues();
              }}
              className={venuesListClasses}
              data-testid="header-select-venue-link"
              {...(isOnVenuesPage && { 'aria-hidden': true })}
            >
              <AiFillHome
                size={20}
                className="link-icon link-icon-left"
                aria-labelledby="selectVenueIconTitle"
              >
                <title id="selectVenueIconTitle">Select Venue Icon</title>
              </AiFillHome>
              <span className="link-label">{selectVenue}</span>
            </Link>
          ) : (
            <div className={venuesListClasses}></div>
          )}
          <Logo />
          {isLoggedIn ? <LoggedInHeader /> : <LoggedOutHeader />}
        </div>
        <ConfirmVenueModal
          show={showConfirmVenueModal}
          proceed={handleConfirmVenueChange}
          dismiss={handleDismissVenueChange}
        />
      </div>
    </nav>
  );
};
