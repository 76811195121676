import { useVenues } from 'contexts/VenueContext';
import { Button } from 'react-bootstrap';
import { FaRegMap } from 'react-icons/fa';

interface MapViewButtonProps {
  handleClick: () => void;
}

export const MapViewButton: React.FC<MapViewButtonProps> = ({
  handleClick,
}) => {
  const { isLoadingVenues } = useVenues();
  return (
    <Button
      className="venue-search-button"
      onClick={handleClick}
      disabled={isLoadingVenues}
    >
      <FaRegMap size={16} />
      <span className="venue-search-button-label">{'Go to map view'}</span>
    </Button>
  );
};
