import { useEffect, useState } from 'react';
import { SelectedServiceChoiceModal } from 'venue/components/SelectedServiceChoiceModal';
import { useConfig } from 'contexts/ConfigContext';
import { LocationSearchButton } from 'venue/components/Search';
import { useVenues } from 'contexts/VenueContext';
import { useVenueSearch } from 'hooks/useVenueSearch';
import { VenueList, VenueMapModal } from './components';
import { SearchBar } from 'menu/components/SearchBar';
import { MapViewButton } from './components/MapComponents';
import { getSupportedServices } from 'venue/utils';
import { useSelector } from 'react-redux';
import { selectHasGoogleMapError } from 'venue/selectors';

export const VenuePage: React.FC = () => {
  const {
    disableSearch,
    enableClickAndCollect,
    enableDeliveryLocation,
    enableOrderAndPay,
    googleMapsApiKey,
    homepage,
    searchText,
    showVenueHomeBanners,
  } = useConfig();

  const { fetchVenues, fetchVenueSummary, venues } = useVenues();
  const { searchTerm, setSearchTerm, filteredVenueList } = useVenueSearch();

  const [showMap, setShowMap] = useState(false);
  const hasGoogleMapError = useSelector(selectHasGoogleMapError);

  const venuesWithCoordinates = venues.filter(
    (venue) =>
      typeof venue?.coordinates?.latitude === 'number' &&
      typeof venue?.coordinates?.longitude === 'number',
  );

  const availableVenuesForMapView = showVenueHomeBanners
    ? venuesWithCoordinates
    : venuesWithCoordinates.filter(
        (venue) =>
          getSupportedServices(
            venue.services,
            enableOrderAndPay,
            enableClickAndCollect,
            enableDeliveryLocation,
          ).length > 0,
      );

  const showMapButton =
    availableVenuesForMapView.length > 0 &&
    googleMapsApiKey &&
    !hasGoogleMapError;

  useEffect(() => {
    fetchVenues();
    fetchVenueSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!disableSearch ? (
        <>
          <div className="venue-search-container">
            <div className="container">
              <div className="venue-search-bar">
                <SearchBar
                  searchTerm={searchTerm}
                  changeSearchTerm={setSearchTerm}
                  clearSearchTerm={() => setSearchTerm('')}
                  placeHolder={searchText}
                />
                <LocationSearchButton />
                {showMapButton ? (
                  <MapViewButton handleClick={() => setShowMap(true)} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="container">
            <VenueMapModal
              isOpen={showMap}
              handleClose={() => setShowMap(false)}
              venueList={availableVenuesForMapView}
            />
            <VenueList venues={filteredVenueList} />
            <SelectedServiceChoiceModal resetVenueOnClose={true} />
          </div>
        </>
      ) : (
        <div className="container container-sm text-center">
          <div
            className="panel panel-default"
            data-testid="header-unexpected-error-text"
          >
            <h3>Something unexpected happened.</h3>
            <a
              href={homepage}
              className="btn btn-primary"
              data-testid="btn-back-to-homepage"
            >
              Back to homepage
            </a>
          </div>
          <SelectedServiceChoiceModal resetVenueOnClose={true} />
        </div>
      )}
    </>
  );
};
