import { useDispatch } from 'react-redux';
import { addNotification } from 'core/actions';
import { useConfig } from 'contexts/ConfigContext';
import { MapMarker, getCenterAndZoom } from 'venue/components/MapComponents';
import { useVenues } from 'contexts/VenueContext';
import { useEffect, useRef } from 'react';
import {
  GoogleMap,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api';
import { MdCancel } from 'react-icons/md';
import { Loader } from 'components/Loader';
import { CombinedVenue } from 'types/models';
import { setGoogleMapError } from 'venue/actions';
interface VenueMapProps {
  handleClose: () => void;
  venueList: CombinedVenue[];
}

export const VenueMap: React.FC<VenueMapProps> = ({
  handleClose,
  venueList,
}) => {
  const { googleMapsApiKey } = useConfig();
  const { isLoadingVenues } = useVenues();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
  });
  const dispatch = useDispatch();

  if (!googleMapsApiKey) {
    console.log('No Google Maps API key');
    handleClose();
    dispatch(setGoogleMapError());
  }
  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  useEffect(() => {
    window.gm_authFailure = () => {
      // Close window, show error banner and hide map button for future clicks
      handleClose();
      dispatch(setGoogleMapError());
      dispatch(
        addNotification(
          'Sorry something went wrong, map view is currently unavailable.',
          'danger',
        ),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (venueList.length > 0) {
    const { center, zoom } = getCenterAndZoom(venueList);
    const containerStyle = {
      width: '100%',
      height: '100%',
    };
    const options = {
      streetViewControl: false,
      mapTypeControl: false,
      clickableIcons: false,
      fullscreenControl: false,
      disableAutoPan: true,
    };
    return isLoaded && !isLoadingVenues && venueList.length > 0 ? (
      <div className="map-container">
        <MdCancel
          size={42}
          onClick={handleClose}
          tabIndex={0}
          className="map-close"
          aria-label="Close map"
        />
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          options={options}
          onLoad={onLoad}
        >
          <MarkerClusterer>
            {(clusterer) => (
              <>
                {venueList.map((venue) => (
                  <MapMarker
                    venue={venue}
                    clusterer={clusterer}
                    key={venue.id}
                  />
                ))}
              </>
            )}
          </MarkerClusterer>
        </GoogleMap>
      </div>
    ) : (
      <div className="text-center">
        <Loader />
      </div>
    );
  }
  if (!isLoadingVenues) {
    return (
      <div className="text-center">
        <Loader />
      </div>
    );
  }
  return null;
};
