import { RootStateOrAny } from 'react-redux';

export const selectIsServiceChoiceModalOpen = (
  state: RootStateOrAny,
): boolean => state.venue.get('serviceChoiceModalFlag');

export const selectModalShown = (state: RootStateOrAny): boolean =>
  state.venue.get('modalShown');

export const selectHasGoogleMapError = (state: RootStateOrAny): boolean =>
  state.venue.get('hasGoogleMapError');
