import { Modal } from 'components/Modal';
import { CombinedVenue } from 'types/models';
import { VenueMap } from 'venue/components/VenueMap';

interface VenueMapModalProps {
  isOpen: boolean;
  handleClose: () => void;
  venueList: CombinedVenue[];
}

export const VenueMapModal: React.FC<VenueMapModalProps> = ({
  isOpen,
  handleClose,
  venueList,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="venue-map-modal"
    >
      <VenueMap handleClose={handleClose} venueList={venueList} />
    </Modal>
  );
};
